import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

function Breadcrumb({category,categoryId,product}) {
  return (
    <section className='bread mb-5'>
        <div className='container mx-auto'>
            <ul className='bread_list flex flex-wrap'>
                <li>
                    <a href='/' className='font-primary text-mydarkcolor md:text-base text-sm mx-2'>Home</a> <FontAwesomeIcon icon={faChevronRight}/>
                </li>
                <li>
                    <Link to={`/category/${categoryId}`} className='font-primary text-mydarkcolor md:text-base text-sm mx-2'>{ category } </Link> <FontAwesomeIcon icon={faChevronRight}/>
                </li>
                <li>
                    <a href='/' className='font-primary text-mydarkcolor md:text-base text-sm mx-2'>{ product }</a>
                </li>
            </ul>
        </div>
    </section>
  )
}

export default Breadcrumb