import React from 'react'
import MenuLinks from './MenuLinks'

function Navbar() {
  return (
    <section className='md:block hidden bg-myorangeolor leading-[57px]'>
        <div className='container mx-auto'>
           <MenuLinks set="flex-row" myMargin="mx-7"/>
        </div>
    </section>
  )
}

export default Navbar