import React, { useEffect, useState } from 'react';


function Popup({isAllowed,setIsAllowed}) {


const [isOpen,setIsOpen] = useState('hidden');


useEffect(()=>{
    let IsPopupAlreadyOpened = localStorage.getItem('IsPopupAlreadyOpened');
    
    if(isAllowed == false && IsPopupAlreadyOpened == null){
        
        setIsOpen('');
    }
},[]);
const HandleClick = (value) =>{

    if(value === 'No'){
        window.location.replace('https://google.com');
    }else{
        localStorage.setItem('IsPopupAlreadyOpened','true')
        setIsAllowed(true);
        setIsOpen('hidden');
    }
}

  return (
    <>
      
<div className={`fixed z-10 overflow-y-auto top-0 w-full left-0 ${isOpen}`} id="modal">
  <div className="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0"> 

    <div className="inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
    <div className="relative bg-[#0b348c] rounded-lg shadow dark:bg-gray-700">
                    <div className="p-6 space-y-6 flex flex-col justify-center justify-items-center">
                        <div className='m-auto'>
                            <img src={require('../Images/headerLogo.png')} alt="Logo" className='inline-block m-auto'/>
                        </div>
                        <div className='w-full'>
                            <p className="text-[20px] leading-relaxed font-bold text-white w-full text-center">
                                Age Verification
                            </p>
                            <p className="text-[40px] leading-relaxed font-bold text-white w-full text-center mb-5">
                                    ARE YOU 21+?
                            </p>                        
                            <p className="text-[20px] leading-relaxed text-white w-full text-center">
                            MANY OF THE PRODUCTS AND SERVICES ON THIS WEBSITE ARE INTENDED FOR ADULTS ONLY. By entering this website, you certify that you are of legal smoking age in the state in which you reside.
                            </p>                            
                        </div>                       
                    </div>        
                    <div className="flex items-center justify-center p-6 space-x-2 rounded-b dark:border-gray-600">
                        <button type="button" onClick={() => HandleClick('Yes')} className="text-white bg-green-400 hover:bg-green-600 font-medium rounded-lg text-[20px] px-10 py-5 text-center popupBtn">Yes, I am over 21</button>
                        <button type="button" onClick={() => HandleClick('No')} className="text-white bg-red-600 hover:bg-red-700  rounded-lg text-[20px] px-10 py-5 popupBtn">No, I am not</button>
                    </div>
                </div>
    </div>
  </div>
</div>
    </>
  );
}

export default Popup;