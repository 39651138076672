import React, { useState } from 'react'
import HomeTopBanner from './HomeTopBanner'
import TopOffer from './TopOffer'
import TopSelling from './TopSelling'
import { useSelector } from 'react-redux';
import Loading from './Loading';
import Popup from './Popup';

function Home() {
    const [isAllowed,setIsAllowed] = useState(false);  
  const { banners,isBannerLoading } = useSelector(( state )=> state.banners);
  const { topProducts,isLoading } = useSelector(( state )=> state.products);
  const { categories,isCatLoading } = useSelector(( state )=> state.categories);
 if(isBannerLoading || isLoading || isCatLoading){ return <Loading />; }
 if(!isBannerLoading && !banners.length){ return 'No Posts'; }


  return (
    <>
      <Popup isAllowed={isAllowed} setIsAllowed={setIsAllowed} />
      <HomeTopBanner banner={banners[2]} />
      <TopSelling title="Browse Top Selling Categories" page='home' drinksBanner={banners[0]} cigarettesBanner={banners[1]} categories={categories} />
      {/* <TopOffer topProducts={topProducts}/> */}


    </>
  )
}

export default Home