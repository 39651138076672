import React from 'react'

function BrandLogos() {
  return (
    <section className='brand_logos py-9 bg-mybluecolor items-center'>
        <div className='container mx-auto'>
            <ul className='brd_logos flex md:flex-row flex-wrap md:gap-4 gap-2 md:mx-0 mx-3 justify-center'>
                <li><img src={require('../Images/cli_logo_img1.png')} alt='' className='w-full'/></li>
                <li><img src={require('../Images/cli_logo_img2.png')} alt='' className='w-full'/></li>
                <li><img src={require('../Images/cli_logo_img3.png')} alt='' className='w-full'/></li>
                <li><img src={require('../Images/cli_logo_img4.png')} alt='' className='w-full'/></li>
                <li><img src={require('../Images/cli_logo_img5.png')} alt='' className='w-full'/></li>
                <li><img src={require('../Images/cli_logo_img6.png')} alt='' className='w-full'/></li>
            </ul>
        </div>
    </section>
  )
}

export default BrandLogos