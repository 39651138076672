import React from 'react'

export const ThankyouFroContactUs = () => {
  return (
    <section className='thank_sec py-[8%]'>
        <div className='container mx-auto'>
            <div className='text-center md:mx-38 mx-12'>
                <p className='text-black font-primary md:text-4xl text-2xl font-semibold'>Thank you for Contacting us. Our team will contact with you <span className='text-myorangeolor'>Soon !</span></p>
            </div>
        </div>
    </section>
  )
}
