import React, { useState,useEffect } from 'react'
import {  Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser,faCartShopping,faClose } from '@fortawesome/free-solid-svg-icons'
import MenuLinks from './MenuLinks'

import { SearchBar } from './SearchBar'

function TopHeader({cartCount,user,navbar, setNavbar}) {
    


    const [headerFixed, setheaderFixed] = useState("relative w-full z-10");
    const [hideColumn,setHideColumn] = useState('md:block hidden');
    const listenScrollEvent = () => {
        window.scrollY > 100 ? setheaderFixed("fixed w-full top-0 z-10 mobileTopMinusTen") : setheaderFixed("relative w-full z-10");
        window.scrollY > 100 ? setHideColumn("md:block hidden") : setHideColumn("md:block hidden");
    };

    useEffect(() => {
          window.addEventListener("scroll", listenScrollEvent);
        return () => {
          window.removeEventListener("scroll", listenScrollEvent);
        };
      }, []);

  return (
    <>
    <section className='transition-all delay-150 top_header md:mx-0 px-3 py-4  bg-mybluecolor'>
        <div className='container mx-auto'>
            <div className='my_top_header flex md:flex-row flex-col gap-4 relative items-center'>

                <div className='md:basis-3/12 basis-full text-left'>
                    <Link to='/' end>
                        <img src={require('../Images/logo-shopwex.png')} alt="Logo" className='inline-block'/>
                    </Link>
                    <div className="md:hidden flex items-center absolute top-7 right-1">
                        {navbar ? 
                        <button className="outline-none mobile-menu-button"  onClick={() => setNavbar(!navbar)}>
                            <FontAwesomeIcon icon={faClose} className="text-2xl text-myorangeolor"/>
                        </button>
                        : 
                        <button className="outline-none mobile-menu-button"  onClick={() => setNavbar(!navbar)}>
                            <svg
                                className="w-6 h-6 text-myorangeolor"
                                x-show="!showMenu"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                viewBox="0 0 24 24" 
                                stroke="currentColor"
                            >
                                <path d="M4 6h16M4 12h16M4 18h16"></path>
                            </svg>
                        </button> 
                        }
                        
                    </div>

                     {/* Mobile Menu Starts Here */}
               
                     <div
                        className={`my_menus p-5 md:hidden mt-4 mb-0
                        relative bg-mybluecolor w-full
                        ${
                            navbar ? "block" : "hidden"
                        }`}
                    >
                        
                        
                        <MenuLinks user={user} set="flex-col" myMargin="mx-3"/>
                        
                    </div>
                    {/* Mobile Menu Ends Here */}


                </div>

                <div className='md:basis-6/12 basis-full'>

                    <SearchBar />

                </div>

                <div className={`md:block md:basis-3/12 basis-full`}>
                    <ul className='top_links_list flex flex-wrap md:gap-0 md:justify-evenly justify-center gap-5'>
                        { !user? 
                            <>
                                <li className=''>
                                    <Link to='/login' className='text-sm text-white font-primary font-medium duration-200 group-hover:text-myorangeolor inline'>
                                    <div className="text-sm m-auto mb-2 text-center rounded-full text-white border-2 border-white w-[40px] h-[40px] leading-9 duration-200 group-hover:border-myorangeolor group-hover:text-myorangeolor">
                                        <FontAwesomeIcon icon={faUser}/>
                                    </div>
                                    Login</Link>
                                </li>
                                <li className=''>
                                    <Link to='/signup' className='text-sm text-white font-primary font-medium duration-200 group-hover:text-myorangeolor inline'>
                                    <div className="text-sm m-auto mb-2 text-center rounded-full text-white border-2 border-white w-[40px] h-[40px] leading-9 duration-200 group-hover:border-myorangeolor group-hover:text-myorangeolor">
                                        <FontAwesomeIcon icon={faUser}/>
                                    </div>
                                    Sign up</Link>
                                </li>
                            </>
                        : 
                            <li className=''>
                                <Link to='/my-orders' className='text-sm text-white font-primary font-medium duration-200 group-hover:text-myorangeolor inline'>
                                    <div className="text-sm m-auto mb-2 text-center rounded-full text-white border-2 border-white w-[40px] h-[40px] leading-9 duration-200 group-hover:border-myorangeolor group-hover:text-myorangeolor">
                                        <FontAwesomeIcon icon={faUser}/>
                                    </div>
                                    My Account</Link>
                            </li>
                        }

                        {/* <li className=''>
                            <Link to='/my-orders' className='text-sm text-white font-primary font-medium duration-200 group-hover:text-myorangeolor inline'>
                                <div className="text-sm m-auto mb-2 text-center rounded-full text-white border-2 border-white w-[40px] h-[40px] leading-9 duration-200 group-hover:border-myorangeolor group-hover:text-myorangeolor">
                                    <FontAwesomeIcon icon={faUser}/>
                                </div>
                                My Account</Link>
                        </li> */}
                        <li className='relative'>
                            <Link to='/cart' className='text-sm text-white font-primary font-medium duration-200 group-hover:text-myorangeolor'>
                               
                                <div className="text-sm m-auto mb-2 text-center rounded-full text-white border-2 border-white w-[40px] h-[40px] leading-9 duration-200 group-hover:border-myorangeolor group-hover:text-myorangeolor">
                                    <FontAwesomeIcon icon={faCartShopping}/>
                                </div>
                                <span className='count_item absolute -top-[7px] right-[5px] w-[15px] h-[20px] cartCount text-white text-center text-sm rounded-full'>{cartCount}</span>
                            My Cart</Link>
                        </li>
                    </ul>
                </div>

                   
                
                
            </div>
        </div>
    </section>
                
    </>
  )
}

export default TopHeader