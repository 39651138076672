const getCartCount = () => {
    const cart = localStorage.getItem('cart');
    let qty = 0;
  
    // If cart exists in localStorage and is a valid array, proceed
    if (cart) {
      const JsonCart = JSON.parse(cart);
      
      if (Array.isArray(JsonCart)) {
        // Use forEach to iterate and sum up the quantities
        JsonCart.forEach((item) => {
          qty += item.qty || 0; // Ensure qty is a valid number
        });
      }
    }
  
    return qty;
  };
  
  export default getCartCount;
  