import React from 'react'

function About() {
  return (
    <>
    <section className='abt_section pt-10 pb-9 md:mx-0 mx-3'>
      <div className='container mx-auto'>
          <div className='abt_title'>
            <h1 className='text-mydarkcolor font-primary text-xl font-bold pb-4 mb-5 border-b-2 border-black'>About us</h1>

            <p className='text-dark font-primary text-base leading-7'>
            Mauris tempus erat laoreet turpis lobortis, eu tincidunt erat fermentum. Aliquam non tincidunt urna. Integer tincidunt nec nisl vitae ullamcorper. Proin sed ultrices erat. Pellentesque sodales augue eget ultricies ultricies. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Curabitur sagittis ultrices condimentum. Aliquam non tincidunt urna. Integer tincidunt nec nisl vitae ullamcorper. Proin sed ultrices erat. Praesent varius ultrices massa at faucibus. Aenean dignissim, orci sed faucibus pharetra, dui mi dignissim tortor, sit amet condimentum mi ligula sit amet augue. Pellentesque vitae eros eget enim mollis placerat.
            </p>
          </div>
      </div>
    </section>
    <section className='abt_btm_img text-center'>
      <img src={require('../Images/abt_page_banner.png')}/>
    </section>
    </>
  )
}

export default About