import * as api from '../api';
import * as actionType from '../constants/actionTypes';

// Action creator

export const getSlides = () => async (dispatch) => {
    try {
        dispatch({type:actionType.START_SLIDE_LOADING});
        const {data} = await api.fetchSlides();
        dispatch({type:actionType.FETCH_ALL_SLIDES, payload:data});
        dispatch({type:actionType.END_SLIDE_LOADING});
    } catch (error) {
        console.log(error.message);
    }
}
