import * as actionTypes from '../constants/actionTypes';

export default (state = { isWidgetLoading:true,vapes:'',drinks:'' }, action) => {

    switch (action.type) {
       case actionTypes.FETCH_PAGE_VAPES:
            return {
                ...state,
                vapes:action.payload.data,               
            };
       case actionTypes.FETCH_PAGE_DRINKS:
         return {
             ...state,
             drinks:action.payload.data,               
        };
       case actionTypes.START_WIDGETS_LOADING:
            return {...state,isWidgetLoading:true}
       case actionTypes.END_WIDGETS_LOADING:
            return {...state,isWidgetLoading:false}
        default:
            return state;
    }

}