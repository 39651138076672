import React,{useState} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faCaretDown,faCaretUp,faList,faTableCells } from '@fortawesome/free-solid-svg-icons'
import ProductWidget from './ProductWidget'
import AddToCartPopup from '../AddToCartPopup';



export const SearchProductSection = ({items,columnset,setColumnSet,cartCount,setCartCount,SetCartMsg}) => {
    const [OpenPopup,setOpenPopup] = useState(false);    
  return (
    <>
    <AddToCartPopup OpenPopup={OpenPopup} setOpenPopup={setOpenPopup} />
    <div className='cat_product_list basis-4/5'>
                        
    <div className='top_cat_titles flex md:flex-row flex-col pb-4 border-b-2 border-[#898989] mb-4 md:mt-5 mt-0'>
        <div className='cat_top_title basis-2/4'>
            {/* <h4 className='font-primary text-mydarkcolor text-xl font-semibold md:text-left text-center md:mb-0 mb-3'>{category.title}</h4> */}
        </div>
        <div className='cat_totals basis-2/4 md:text-right text-center'>
            {/* <p className='text-mydarkcolor font-primary md:text-base text-sm md:text-right text-center inline-block'>Showing 1–24 of 340 results</p> */}
            <ul className='toggles_list inline-block'>
                <li className='inline-block'>
                    <button onClick={() => setColumnSet('md:basis-[19%] basis-[45%]')}>
                        <FontAwesomeIcon icon={faTableCells} className="text-mydarkcolor mx-2"/>
                    </button>
                </li>
                <li className='inline-block'>
                    <button onClick={() => setColumnSet('md:basis-full basis-full')}>
                        <FontAwesomeIcon icon={faList} className="text-mydarkcolor "/>
                    </button>
                </li>
            </ul>
        </div>
    </div>
    
    <div className='cat_avaliable_list'>
        <ul className='flex flex-wrap gap-2 justify-center'>                
            {items.map((product)=> (<ProductWidget setOpenPopup={setOpenPopup} columnset={columnset} product={product} key={product._id} setCartCount={setCartCount} cartCount={cartCount} SetCartMsg={SetCartMsg} />))}                      
        </ul>
    

    
    </div>
    
    </div>
    </>
  )
}
