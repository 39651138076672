import React from 'react';
import { useFormik } from 'formik';
import { FormSchema } from './FormSchema';
import { signIn } from '../actions/auth';
import { clearError } from '../actions/general';
import { Error } from './Error';
import { useDispatch } from 'react-redux';
import { Link, redirect, useNavigate } from 'react-router-dom';

function Login() {
    const dispatch = useDispatch();
    const history = useNavigate();
   
    const initialvalues = {
        email: "",
        password : "",
    }
    const formik = useFormik({
        initialValues: initialvalues,
        // validationSchema: FormSchema,
        //validationSchema: FormScheme,
        onSubmit: values => {
            dispatch(clearError());
            dispatch(signIn(values,history));
        },
      });

  return (
    <>
        <section className='form_section my-9'>
            <div className='container mx-auto'>
                <div className='login_row flex md:flex-row flex-col-reverse md:mx-0 mx-3 gap-5'>
                    <div className='login_form basis-2/4 p-6 bg-myorangeolor'>
                        <h3 className='text-white uppercase font-primary text-xl font-semibold mb-10'>Customer Login</h3>
                        <Error />
                        <form onSubmit={formik.handleSubmit}>
                            <div className='form_row'>
                                <label className='text-white font-primary text-base font-semibold mb-3 block'>Email Address</label>
                                <input
                                    type="email"
                                    placeholder="Enter your email address"
                                    name="email"
                                    value={formik.values.email}
                                    onBlur = {formik.handleBlur}
                                    onChange={formik.handleChange}
                                    className="p-4 placeholder-black text-black relative bg-white text-sm 
                                    border-b-2 border-solid w-full mb-4 font-normal"
                                />
                                { formik.errors.email && formik.touched.email ? 
                                    (<span className='my_vaid_error text-red-600 block mb-2'>{formik.errors.email}</span>)
                                    : null
                                }
                            </div>
                            <div className='form_row'>
                                <label className='text-white font-primary text-base font-semibold mb-3 block'>Password</label>
                                <input
                                    type="password"
                                    placeholder="........"
                                    name="password"
                                    value={formik.values.password}
                                    onBlur = {formik.handleBlur}
                                    onChange={formik.handleChange}
                                    className="p-4 placeholder-black text-black relative bg-white text-sm 
                                    border-b-2 border-solid w-full mb-4 font-normal"
                                />
                                { formik.errors.password && formik.touched.password ? 
                                    (<span className='my_vaid_error text-red-600 block mb-2'>{formik.errors.password}</span>)
                                    : null
                                }
                            </div>
                            <div className='form_row flex md:flex-row flex-col'>
                                <div className='basis-2/4'>
                                    <button type='submit' className='bg-mybluecolor text-sm font-primary text-white px-6 py-2 border-2 border-mybluecolor
                                    duration-300 hover:bg-transparent'>Login</button>
                                </div>
                                <div className='basis-2/4'>
                                    <button type='button' className='text-sm font-primary text-white md:px-6 px-0 py-2'>Forgot your password? </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className='intro_block_login basis-2/4 p-6 bg-mybluecolor'>
                        <h3 className='text-white uppercase font-primary text-xl font-semibold mb-10'>Are you a new customer?</h3>
                        <p className='text-white font-primary font-normal mb-8 text-base'>Sign up for a free account at Wholesale Xpress</p>
                      
                        <Link to={`/signup`} className='text-white font-primary font-normal mb-10 text-base py-2 px-6
                         bg-myorangeolor border-2 border-myorangeolor duration-300 hover:bg-transparent'>Sign up  new account </Link>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Login