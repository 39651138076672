import React, { useState,useEffect } from 'react';
import { useDispatch,useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getProduct,getProductsByCategory } from '../actions/products';
import Breadcrumb from './Breadcrumb';
import Related from './Related';
import { getCategory } from '../actions/categories';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus,faPlus,faCaretDown,faCaretUp } from '@fortawesome/free-solid-svg-icons';
import Loading from './Loading';
import { SERVER_URL } from '../constants/actionTypes';
import getCartCount from '../libs/utils';

function Product({ setCartCount,cartCount }) {
    const user = localStorage.getItem('profile');
    const cart = localStorage.getItem('cart');
    // localStorage.removeItem('cart');
    const {product, products,isLoading} = useSelector((state) => state.products);
    const {isCatLoading, category} = useSelector((state) => state.categories);
    const [catList,setCatList] = useState(false);
    console.log(category);
    const dispatch = useDispatch();
    const {id} = useParams();
    const [qtyBtn,SetQtyBtn] = useState(1);    
    const [cartMsg,SetCartMsg] = useState(false);    
    useEffect(() => {
        dispatch(getProduct(id)); 
    },[id]);
    useEffect(()=>{
        if(product){
            dispatch(getProductsByCategory(product.category_ids[0]));
        }
    },[product]);

    const addToCart = () => {
        let data = {
            product : product,
            qty : qtyBtn,
            _id : product._id
        };
        //  if(product.inventory >= qtyBtn ){
            if(!cart){
                localStorage.setItem('cart',JSON.stringify([data]));
               
            }else{
                const JsonCart = JSON.parse(cart);
                if(JsonCart.filter(e => e._id === product._id).length > 0){
                    let newCart = JsonCart.map((c)=> {
                        if(c._id === product._id){
                            let newData = {
                                product : product,
                                qty : qtyBtn+c.qty,
                                _id : product._id   
                            }
                            return newData; 
                        }
                        return c;
                    } );
                    localStorage.setItem('cart',JSON.stringify(newCart));    
                   
                    SetQtyBtn(1);                
                }else{
                    JsonCart.push(data);    
                    localStorage.setItem('cart',JSON.stringify(JsonCart));                 
                }              
            }

            let q = getCartCount();
            setCartCount(q);
            SetCartMsg('Product Added ');                
        // }else{
        //     SetCartMsg('You can not purchased more then available stock');    
        // }       
    }

    const { categories } = useSelector(( state )=> state.categories);
    
    const nav = categories.map((category)=>(
        <li key={category._id}>
         <Link to={`/category/${category._id}`} className='p-4 font-primary bg-mybluecolor text-white text-sm block duration-300
         border-b-2 border-white hover:bg-myorangeolor'>{category.title}</Link> 
         </li> ));

 if(isLoading){ return <Loading />; }

 
 const recommendedProducts = products.filter(({ _id }) => _id !== product._id);

 

  return (
    <>
        
        <section className='product_details pt-5 md:pb-10 pb-4'>
            <div className='container mx-auto'>
                <div className='main_single_section flex md:flex-row flex-col gap-5'>
                    <div className='md:basis-1/5 basis-full cat_tabs'>
                        <h3 className='text-white font-primary p-4 bg-myorangeolor block relative'>
                                <button className='block w-full text-left' onClick={() => setCatList(!catList)}>
                                    Shop by Categories
                                    <div className='icons_div md:hidden block'>
                                        {
                                            catList ?
                                            <FontAwesomeIcon icon={faCaretDown} className="absolute right-3 top-5"/>
                                            :
                                            <FontAwesomeIcon icon={faCaretUp} className="absolute right-3 top-5"/>
                                        }
                                    </div>
                                </button>
                        </h3>                    
                        <ul className={`bg-mybluecolor ${catList  ? 'block' :'md:block hidden' }`}>
                            {nav}                            
                        </ul>
                    </div>
                    <div className='md:basis-4/5 basis-full'>
                        <Breadcrumb product={product.title} category={category.title} categoryId={category._id}/>
                        <div className='details_pro_block flex md:flex-row flex-col md:mx-0 mx-3 gap-4'>
                            <div className='pro_img basis-2/4'>
                                <img src={product.thumbnail !== '' ? SERVER_URL+product.thumbnail : require('../Images/detail-pro-img.png')} className="w-full border-4 border-mybluecolor rounded-lg" alt={product.title} />
                            </div>
                            <div className='pro_all_info basis-4/5'>
                                <h4 className='text-black font-primary text-lg font-semibold my-5'>{ product.title}</h4>
                                <p className='sk_num text-black font-primary text-lg mb-5'><span className='font-semibold'>Item No :</span> {product.item_no}</p>
                                {/* <p className='sk_num text-black font-primary text-lg mb-5'><span className='font-semibold'>Inventory :</span> {product.inventory}</p> */}
                                {user?(
                                    <p className='text-black font-primary text-xl mb-5'>${product.price}</p>
                                ):''}                      
                                <div className='cart_form flex md:flex-row flex-col gap-3'>
                                    <div className='qty_div'>
                                        <ul className='qty_list flex flex-row mb-4'>
                                            <li>
                                                <button className='text-sm text-xl px-3 py-2 border-2 border-[#b6b7b7] border-r-0' onClick={() => {if(qtyBtn>1){SetQtyBtn(qtyBtn - 1)}}}>
                                                    <FontAwesomeIcon icon={faMinus}/>
                                                </button>
                                            </li>
                                            <li>
                                                <input
                                                type="number"
                                                name="pro_qty_number"
                                                value={qtyBtn}
                                                onChange={ 
                                                    (e) => { 
                                                        //SetQtyBtn(e.target.value)  

                                                        let num = e.target.value;
                                                        num < 1 ? SetQtyBtn(1) : SetQtyBtn(num); 
                                                    } 
                                                }
                                                className="p-1 placeholder-black text-white relative bg-myorangeolor text-sm 
                                                w-14 font-normal border-2 border-myorangeolor min-h-[40px]"
                                                />
                                            </li>
                                            <li>
                                                <button className='text-sm text-xl px-3 py-2 border-2 border-[#b6b7b7] border-l-0' onClick={() => {SetQtyBtn(qtyBtn + 1)}}>
                                                    <FontAwesomeIcon icon={faPlus}/>
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                    
                                    <div className='add_button md:mb-0 mb-4'>
                                    {user?(
                                        <button 
                                            onClick={addToCart}
                                            className='bg-mybluecolor text-sm font-primary text-white px-3 py-2 border-2 border-mybluecolor duration-300 hover:bg-myorangeolor hover:border-myorangeolor'>
                                            Add To Cart
                                        </button>
                                    ):
                                    <button  
                                    className='bg-mybluecolor text-sm font-primary text-white px-3 py-2 border-2 border-mybluecolor duration-300 hover:bg-myorangeolor hover:border-myorangeolor'>
                                            Login to View Price
                                    </button>
                                    } 
                            
                                    </div>
                                
                                </div>
                                { (cartMsg != false )? ( 
                                        <div className='cartMsg'>{cartMsg}</div>
                                    ) : '' }
                                <br/>
                                <br/>
                                <br/>
                                {product.short_description}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <Related products={recommendedProsducts}/> */}
    </>
  )
}

export default Product