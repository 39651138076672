export const FETCH_ALL = 'FETCH_ALL';
export const FETCH_TOP_PRODUCTS = 'FETCH_TOP_PRODUCTS';
export const FETCH_ALL_BY_SEARCH = 'FETCH_ALL_BY_SEARCH';
export const START_BANNER_LOADING = 'START_BANNER_LOADING'; 
export const END_BANNER_LOADING = 'END_BANNER_LOADING'; 

export const CREATE = 'CREATE';
export const UPDATE = 'UPDATE';
export const DELETE = 'DELETE';
export const LIKE = 'LIKE';
export const COMMENT = 'COMMENT';
export const START_LOADING = 'START_LOADING';
export const END_LOADING = 'END_LOADING';
export const FETCH_POST_BY_ID = 'FETCH_POST_BY_ID';


export const FETCH_ALL_BANNERS = 'FETCH_ALL_BANNERS';
export const FETCH_BANNER_BY_ID = 'FETCH_BANNER_BY_ID';



export const FETCH_ALL_PRODUCTS_BY_SEARCH = 'FETCH_ALL_PRODUCTS_BY_SEARCH';
export const FETCH_ALL_PRODUCTS = 'FETCH_ALL_PRODUCTS';
export const FETCH_PRODUCT_BY_ID = 'FETCH_PRODUCT_BY_ID';
export const FETCH_ALL_PRODUCTS_BY_CATEGORY = 'FETCH_ALL_PRODUCTS_BY_CATEGORY';


export const START_CAT_LOADING = 'START_CAT_LOADING';
export const END_CAT_LOADING = 'END_CAT_LOADING';
export const FETCH_ALL_CATEGORIES = 'FETCH_ALL_CATEGORIES';
export const FETCH_CATEGORY = 'FETCH_CATEGORY';


export const START_SLIDE_LOADING = 'START_SLIDE_LOADING';
export const END_SLIDE_LOADING = 'END_SLIDE_LOADING';
export const FETCH_ALL_SLIDES = 'FETCH_ALL_SLIDES';





export const AUTH = 'AUTH';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_ERROR_REMOVE = 'AUTH_ERROR_REMOVE';
export const AUTH_SIGNUP_MSG = 'AUTH_SIGNUP_MSG';

export const AUTH_LOADING = 'AUTH_LOADING';
export const AUTH_END_LOADING = 'AUTH_END_LOADING';

export const LOGOUT = 'LOGOUT';

export const SERVER_URL = 'https://admin.shopwex.com';
// export const SERVER_URL = 'http://localhost:5000';

export const START_ORDER_LOADING = 'START_ORDER_LOADING';
export const END_ORDER_LOADING = 'END_ORDER_LOADING';
export const FETCH_ALL_ORDER = 'FETCH_ALL_ORDER';
export const FETCH_ORDER_BY_ID = 'FETCH_ORDER_BY_ID';


export const FETCH_PAGE_VAPES = 'FETCH_PAGE_VAPES';
export const FETCH_PAGE_DRINKS = 'FETCH_PAGE_DRINKS';
export const START_WIDGETS_LOADING = 'START_WIDGETS_LOADING';
export const END_WIDGETS_LOADING = 'END_WIDGETS_LOADING';

