import React, { useState,useEffect } from 'react'
import Navbar from './Navbar'
import TopHeader from './TopHeader'

function Header({cartCount,user,logout,navbar,setNavbar}) {

  const [headerFixed, setheaderFixed] = useState("relative w-full z-10");
    const [hideColumn,setHideColumn] = useState('md:block hidden');
    const listenScrollEvent = () => {
        window.scrollY > 100 ? setheaderFixed("fixed w-full top-0 z-10 mobileTopMinusTen") : setheaderFixed("relative w-full z-10");
        window.scrollY > 100 ? setHideColumn("md:block hidden") : setHideColumn("md:block hidden");
    };

    useEffect(() => {
          window.addEventListener("scroll", listenScrollEvent);
        return () => {
          window.removeEventListener("scroll", listenScrollEvent);
        };
      }, []);

  return (
    <>
      <header className ={`${headerFixed}`}>
        <TopHeader cartCount={cartCount} user={user} logout={logout} navbar={navbar} setNavbar={setNavbar} />
        <Navbar/>
      </header>
    </>
  )
}

export default Header