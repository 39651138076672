
import React, { useEffect, useState } from 'react';
import { useDispatch,useSelector } from 'react-redux';
import { Link,useParams, useNavigate } from 'react-router-dom';
import moment from 'moment/moment';
import { SERVER_URL } from '../constants/actionTypes';

import { getOrder } from '../actions/order';
import Loading from './Loading';

export default function MyOrder({user,logout}) {
    const {id} = useParams();
    const dispatch = useDispatch();
    const {order,isOrderLoading} = useSelector((state) => state.order);

    useEffect(()=>{
        dispatch(getOrder(id));
    },[id]);

   
 if(isOrderLoading){ return <Loading />; }


  return (
    <>
        <section className='form_section my-9'>
            <div className='container mx-auto'>
                <div className='login_row p-5 bg-mylightcolor'>
                    <h3 className='font-primary text-black text-xl font-semibold uppercase mb-5'>Order Details  </h3>
                    <div className='flex flex-row relative'>
                       <div className='boxCon'>
                            <h3>Customer Details</h3>
                            <ul className='customerDetails'>
                                <li><span>Name: </span>{ order?.customer?.name }</li>
                                <li><span>Email: </span>{ order?.customer?.email }</li>
                                <li><span>Phone: </span>{ order?.customer?.phone }</li>
                                <li><span>Business Name: </span>{ order?.customer?.business_name }</li>
                                <li><span>Business Address: </span>{ order?.customer?.business_address }</li>
                            </ul>
                       </div>
                       <div className='boxCon'>
                            <h3>Order Details</h3>
                            <ul className='orderDetails'>
                                <li><span>Total: </span>${ order?.orderDetails?.total }</li>
                                <li><span>Order Type: </span>{ order?.orderDetails?.type }</li>
                                <li><span>Order Created At: </span>{ order?.customer?.phone }</li>                            
                            </ul>
                       </div>
                       <div className='logoutBtn'>
                       <button onClick={logout} className='text-sm text-mydarkcolor font-primary font-medium duration-200 group-hover:text-myorangeolor inline'>Logout</button>
                       </div>
                    </div>
                    <div className='flex flex-row'>
                       <div className='productBox'>
                            <h3>Order Products</h3>
                            <ul className='productDetails'>
                                {(order.products) ? order.products.map((product)=>(
                                    <li key={product._id} className='flex'>
                                        <div className='w-1/3 liLeftCol'>
                                            <span className='productImg'>
                                                <img src={product.product.thumbnail !== '' ? SERVER_URL+product.product.thumbnail : require('../Images/pro_img2.png')} />
                                            </span>
                                           
                                        </div>
                                        <div className='w-1/3 liRightCol'>
                                        <span className='productTitle'>
                                                {product.product.title}
                                            </span>
                                            <br />
                                            <span className='productQty'>
                                                <b>QTY: </b>{product.quantity}
                                            </span><br/>
                                            <span className='productPrice'>                        
                                                ${product.price}
                                            </span>
                                        </div>
                                    </li>
                                )) : ''}
                            </ul>
                       </div>
                    </div>
                    
                </div>  
            </div>
        </section>
    </>
  )
}
