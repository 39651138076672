import * as actionTypes from '../constants/actionTypes';

export default (state = { isCatLoading:true,category:'', categories:[] }, action) => {

    switch (action.type) {
        case actionTypes.START_CAT_LOADING:
            return {...state,isCatLoading:true}
       
       case actionTypes.END_CAT_LOADING:
            return {...state,isCatLoading:false}
       
       case actionTypes.FETCH_ALL_CATEGORIES:
            return {
                ...state,
                categories:action.payload.data,    
            };       
       case actionTypes.FETCH_CATEGORY:
            return {...state, category:action.payload.data};
       
        default:
            return state;
    }

}