import * as api from '../api';
import * as actionType from '../constants/actionTypes';

// Action creator

export const getBanners = () => async (dispatch) => {
    try {
        dispatch({type:actionType.START_BANNER_LOADING});
        const {data} = await api.fetchBanners();
        dispatch({type:actionType.FETCH_ALL_BANNERS, payload:data});
        dispatch({type:actionType.END_BANNER_LOADING});
    } catch (error) {
        console.log(error.message);
    }
}
