import { combineReducers } from "redux";

import posts from "./posts";
import authReducer from "./auth";
import banners from "./banners";
import products from "./products";
import categories from "./categories";
import slider from "./slider";
import error from "./error";
import order from "./order";
import page from "./page";

export default combineReducers({ posts,authReducer,banners,products,categories,slider,error,order,page });