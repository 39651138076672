import React, { useState } from 'react'
import { SERVER_URL } from '../../constants/actionTypes';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus,faPlus } from '@fortawesome/free-solid-svg-icons';
import EllipsisText from "react-ellipsis-text";
import getCartCount from '../../libs/utils';

function ProductWidget({product,columnset,cartCount,setCartCount,SetCartMsg,setOpenPopup}) {
    const user = localStorage.getItem('profile');
    const cart = localStorage.getItem('cart');
    const [qtyBtn,SetQtyBtn] = useState(1);
    const addToCart = () => {
      
        let data = {
            product : product,
            qty : qtyBtn,
            _id : product._id
        };
        // if(product.inventory >= qtyBtn ){
            if(!cart){
                localStorage.setItem('cart',JSON.stringify([data]));
                
            }else{
                const JsonCart = JSON.parse(cart);
                if(JsonCart.filter(e => e._id === product._id).length > 0){
                    let newCart = JsonCart.map((c)=> {
                        if(c._id === product._id){
                            let newData = {
                                product : product,
                                qty : qtyBtn+c.qty,
                                _id : product._id   
                            }
                            return newData; 
                        }
                        return c;
                    } );
                    localStorage.setItem('cart',JSON.stringify(newCart)); 
                  
                    SetQtyBtn(1);         
                }else{
                    JsonCart.push(data);    
                    localStorage.setItem('cart',JSON.stringify(JsonCart));       
                    SetQtyBtn(1);            
                }              
            }
            SetCartMsg('Product Added ');
            // alert(typeof updateCartCount);
            setOpenPopup(`${product.title} was successfully added to your cart.`);
            let q = getCartCount();
            setCartCount(q);
            
        // }else{
        //     setOpenPopup('You can not purchased more then available stock');    
        // } 
            
    }
    return (
        <>
        <li className={` ${columnset} mb-5 group productWidget`}>
            <div className='pro_img mb-4'>
            {!user?(
                    <Link to={`/login`}>
                        <img src={product.thumbnail !== '' ? SERVER_URL+product.thumbnail : require('../../Images/pro_img2.png')} alt={product.title} />
                    </Link>
                ):
                <Link to={`/product/${product._id}`}>
                    <img src={product.thumbnail !== '' ? SERVER_URL+product.thumbnail : require('../../Images/pro_img2.png')} alt={product.title} />
                </Link>
                }
               
            </div>
            <div className='pro_details'>
            {!user?(<Link to={'/login'}>
                    <div className='widgetBottom priceBottom flex flex-col justify-between'>
                        <h4 className='font-primary text-white md:text-base text-sm mb-5 md:min-h-[28px] min-h-0'> <EllipsisText text={product.title} length={"50"} /></h4> 
                        <Link to={`/login`} className='WidgetBtn text-[12px]'>
                            Login to view Price
                        </Link>
                    </div>
                </Link>
            ):(
                <div className='widgetBottom AddToCartBottom'>
                     <Link className='FixLink' id='FixLink' to={`/product/${product._id}`}> 
                        <h4 className='font-primary text-white md:text-base text-sm mb-5 md:min-h-[50px] capitalize '><EllipsisText text={product.title} length={"50"} /></h4> 
                     </Link>
                    <h6 className='font-primary text-white md:text-base text-sm mb-5 md:min-h-[10px] min-h-0'>$ {product.price}</h6> 
                    
                    <div className='qty_div'>
                      <ul className='qty_list justify-center flex flex-row mb-4'>
                          <li>
                             <button className='text-sm text-xl px-3 py-2 border-2 bg-white border-[#fff] border-r-0' onClick={() => {if(qtyBtn>1){SetQtyBtn(qtyBtn - 1)}}}>
                                 <FontAwesomeIcon icon={faMinus}/>
                             </button>
                          </li>
                          <li>
                              <input type="number" name="pro_qty_number" value={qtyBtn}
                              onChange={ 
                                  (e) => { 
                                      let num = e.target.value;
                                      num < 1 ? SetQtyBtn(1) : SetQtyBtn(num); 
                                  } 
                              }
                              className="p-1 placeholder-black text-white relative bg-myorangeolor text-sm 
                               w-14 font-normal border-2 border-myorangeolor min-h-[40px] text-center"
                              />
                          </li>
                          <li>
                              <button className='text-sm text-xl px-3 py-2 border-2 bg-white border-[#fff] border-l-0' onClick={() => {SetQtyBtn(qtyBtn + 1)}}>
                                  <FontAwesomeIcon icon={faPlus}/>
                              </button>
                          </li>
                      </ul>                      
                    </div>
                    <button 
                      onClick={addToCart}
                      className='WidgetBtn'>
                         Add To Cart
                      </button>
                </div>
            )}
                
                {/* {!user?(
                    <Link to={`/login`}>
                       <h4 className='font-primary text-black md:text-base text-sm mb-5 md:min-h-[48px] min-h-0'>{product.title}</h4>
                    </Link>
                ):
                <Link to={`/product/${product._id}`}>
                   <h4 className='font-primary text-black md:text-base text-sm mb-5 md:min-h-[48px] min-h-0'>{product.title}</h4>
                </Link>
                }
            

                {user?(
                    <h3 className='font-primary text-black md:text-base text-sm mb-5 md:min-h-[48px] min-h-0'>$ {product.price}</h3>
                ):''}
                
                
                {!user?(
                    <Link to={`/login`} className='font-primary text-black md:text-base text-sm p-2 border-2 inline-block border-mydarkcolor border-mydarkcolor duration-300 group-hover:bg-myorangeolor group-hover:border-myorangeolor group-hover:text-white'>
                        Login to view Price
                    </Link>
                ):
                <Link to={`/product/${product._id}`} className='font-primary text-black md:text-base text-sm p-2 border-2 inline-block border-mydarkcolor border-mydarkcolor duration-300 group-hover:bg-myorangeolor group-hover:border-myorangeolor group-hover:text-white'>
                   View Details
                </Link>
                } */}
                
            
            </div>
        </li>
</>
);

}

export default ProductWidget