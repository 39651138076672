
import React, { useEffect, useState } from 'react';
import { useDispatch,useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment/moment';

import { getOrders } from '../actions/order';
import Loading from './Loading';

export default function MyOrders({user,logout}) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {orders,isOrderLoading} = useSelector((state) => state.order);

    useEffect(()=>{
        dispatch(getOrders());
    },[]);

  
   
 if(isOrderLoading){ return <Loading />; }


  return (
    <>
        <section className='form_section my-9'>
            <div className='container mx-auto'>
                <div className='login_row p-5 bg-mylightcolor'>
                    <div className='relative'>
                    <h3 className='font-primary text-black text-xl font-semibold uppercase mb-5'>My Orders  </h3>
                    <div className='logoutBtn OrderPAge'>
                       <button onClick={logout} className='text-sm text-mydarkcolor font-primary font-medium duration-200 group-hover:text-myorangeolor inline'>Logout</button>
                       </div>
                    </div>

                    <ul className='orderList'>
                    { orders.map((order)=>( 
                        <li key={order._id}>  
                            <Link to={`/my-order/${order._id}`}>
                            <span className='orderId'>Order Id { moment(order?.createdAt).format("YYMMDD") }</span><br/>
                            <span className='total'>${Number(order?.total).toFixed(2)}</span> 
                            <span className='createdAt'>{ moment(order?.createdAt, "YYYYMMDD").fromNow() }</span>
                            <span className='orderType'>{order?.type}</span> 
                            </Link>
                        </li> 
                    )) }
                    </ul>
                </div>  
            </div>
        </section>
    </>
  )
}
