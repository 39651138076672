import * as api from '../api';
import * as actionType from '../constants/actionTypes';

// Action creator

export const getProducts = (page) => async (dispatch) => {
    try {
        dispatch({type:actionType.START_LOADING});
        const {data} = await api.fetchProducts(page);
        dispatch({type:actionType.FETCH_ALL, payload:data});
        dispatch({type:actionType.END_LOADING});
    } catch (error) {
        console.log(error.message);
    }
}
export const getTopProducts = () => async (dispatch) => {
    try {
        dispatch({type:actionType.START_LOADING});
        const {data} = await api.getTopProducts();
        dispatch({type:actionType.FETCH_TOP_PRODUCTS, payload:data});
        dispatch({type:actionType.END_LOADING});
    } catch (error) {
        console.log(error.message);
    }
}
export const getProduct = (id) => async (dispatch) => {
    try {
        dispatch({type:actionType.START_LOADING});
        const {data} = await api.fetchProduct(id);
        dispatch({type:actionType.FETCH_PRODUCT_BY_ID, payload:data});
        dispatch({type:actionType.END_LOADING});
    } catch (error) {
        console.log(error.message);
    }  
}

export const getProductsBySearch = (searchQuery,history) => async (dispatch) => {
    try {
        dispatch({type:actionType.START_LOADING});
        const {data} = await api.fetchProductBySearch(searchQuery);
        dispatch({type:actionType.FETCH_ALL_PRODUCTS_BY_SEARCH, payload:data});
        dispatch({type:actionType.END_LOADING});
        history(`/product/search?search=${searchQuery}`);
    } catch (error) {
        console.log(error);
    }
}

export const getProductsByCategory = (id) => async (dispatch) => {
    try {
        dispatch({type:actionType.START_LOADING});
        const {data} = await api.fetchProductsByCategories([id]);
        dispatch({type:actionType.FETCH_ALL_PRODUCTS_BY_CATEGORY, payload:data});
        dispatch({type:actionType.END_LOADING});
    } catch (error) {
        console.log(error);
    }
}


