import * as actionTypes from '../constants/actionTypes';

export default (state = { isSlideLoading:true, slides:[] }, action) => {

    switch (action.type) {
        case actionTypes.START_SLIDE_LOADING:
            return {...state,isSlideLoading:true}
       case actionTypes.END_SLIDE_LOADING:
            return {...state,isSlideLoading:false}
       case actionTypes.FETCH_ALL_SLIDES:
            return {
                ...state,
                slides:action.payload.data,               
            };
        default:
            return state;
    }

}