import * as Yup from 'yup';

export const FormSchema = Yup.object({
    email: Yup.string().email().required("This field is required."),
    upass: Yup.string().required("Password is required"),
    name: Yup.string().required("Name is required"),
    uphone: Yup.string()
    .required("This field is Required")
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Phone number is not valid"
    ),
    bname: Yup.string().required("This field is required"),
    uspass: Yup.string().required("This field is required").matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
    'Password must contain small and capital letter,special character and number.'),
    address : Yup.string().required("This field is required"),
   // uemail: Yup.string().email().required("This field is required."),
});