import React from 'react'
import {FormSchema} from './FormSchema';
import { Error } from './Error';
import { useFormik } from 'formik';
import { contactUs,clearError } from '../actions/general';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export default function ContactUs() {
    const dispatch = useDispatch();
    const history = useNavigate();

    const formik = useFormik({
        initialValues: {
          name: '',
          email: '',
          phone: '',
          subject: '',
          u_message: '',
        },
        onSubmit: values => {
          dispatch(clearError());
          dispatch(contactUs(values,history));
            
        },
      });

  return (
    <>
    <section className='abt_section pt-10 pb-9 md:mx-0 mx-3'>
      <div className='container mx-auto'>
          <div className='abt_title'>
            <h1 className='text-mydarkcolor font-primary text-3xl font-bold pb-4 mb-5 border-b-2 border-black'>Get In Touch</h1>

            <p className='text-dark font-primary text-base leading-7'>
            We love hearing from our customers! If you have any questions or feedback about our products, please feel free to 
            contact us by filling out the form below. We strive to provide the best possible customer service and will do our 
            best to get back to you as soon as possible. Thank you for visiting our website and we hope to hear from you soon!
            </p>
          </div>
      </div>
    </section>

    <section className='form_section mb-9'>
        <div className='container mx-auto'>
            <div className='login_row p-5 bg-mylightcolor'>
                <Error />
                <form onSubmit={formik.handleSubmit}>

                    <div className='form_row flex md:flex-row flex-col md:gap-8 gap-3'>

                    <div className='basis-2/4'>
                        <label className='text-black font-primary text-base font-semibold mb-3 block'>Full Name</label>
                        <input
                                type="text"
                                placeholder="Enter your full name"
                                name="name"
                                className="p-4 placeholder-black text-black relative bg-white text-sm 
                                border-b-2 border-solid border-white w-full mb-4 font-normal"
                                value={formik.values.name}
                                onBlur = {formik.handleBlur}
                                onChange={formik.handleChange}
                            />
                            { formik.errors.name && formik.touched.name ? 
                                (<span className='my_vaid_error text-red-600 block mb-2'>{formik.errors.name}</span>)
                                : null
                            }
                    </div>

                    <div className='basis-2/4'>
                        <label className='text-black font-primary text-base font-semibold mb-3 block'>Phone</label>
                        <input
                                type="telephone"
                                placeholder="Enter your phone number"
                                name="phone"
                                className="p-4 placeholder-black text-black relative bg-white text-sm 
                                border-b-2 border-solid border-white w-full mb-6 font-normal"
                                value={formik.values.phone}
                                onBlur = {formik.handleBlur}
                                onChange={formik.handleChange}
                            />
                            { formik.errors.phone && formik.touched.phone ? 
                                (<span className='my_vaid_error text-red-600 block mb-2'>{formik.errors.phone}</span>)
                                : null
                            }
                    </div>

                    </div>

                    <div className='form_row flex md:flex-row flex-col md:gap-8 gap-3'>

                        <div className='basis-2/4'>
                            <label className='text-black font-primary text-base font-semibold mb-3 block'>Email Address</label>
                            <input
                                    type="email"
                                    placeholder="Enter Your Email Address"
                                    name="email"
                                    className="p-4 placeholder-black text-black relative bg-white text-sm 
                                    border-b-2 border-solid border-white w-full mb-6 font-normal"
                                    value={formik.values.email}
                                    onBlur = {formik.handleBlur}
                                    onChange={formik.handleChange}
                                />
                                { formik.errors.email && formik.touched.email ? 
                                    (<span className='my_vaid_error text-red-600 block mb-2'>{formik.errors.email}</span>)
                                    : null
                                }

                        </div>

                        <div className='basis-2/4'>
                            <label className='text-black font-primary text-base font-semibold mb-3 block'>Subject</label>
                            <input
                                    type="text"
                                    placeholder="Enter your Subject"
                                    name="subject"
                                    className="p-4 placeholder-black text-black relative bg-white text-sm 
                                    border-b-2 border-solid border-white w-full mb-6 font-normal"
                                    value={formik.values.subject}
                                    onBlur = {formik.handleBlur}
                                    onChange={formik.handleChange}
                                />
                                { formik.errors.subject && formik.touched.subject ? 
                                    (<span className='my_vaid_error text-red-600 block mb-2'>{formik.errors.subject}</span>)
                                    : null
                                }
                        </div>

                    </div>
                    
                    <div className='form_row flex md:flex-row flex-col md:gap-8 gap-3'>
                        <div className='basis-full'>
                            <label className='text-black font-primary text-base font-semibold mb-3 block'>Message</label>
                            <textarea
                            placeholder="Enter your message here!"
                            name="u_message"
                            className="p-4 placeholder-black text-black relative bg-white text-sm 
                            border-b-2 border-solid border-white w-full mb-6 font-normal"
                            value={formik.values.u_message}
                            onBlur = {formik.handleBlur}
                            onChange={formik.handleChange}>

                            </textarea>
                        </div>
                    </div>

                    <div className='form_row flex md:flex-row flex-col md:gap-8 gap-3'>
                        <div className='basis-full'>
                        <button type='submit' className=' bg-mybluecolor text-sm font-primary text-white px-8 py-3 border-2 border-mybluecolor
                                    duration-300 hover:bg-myorangeolor hover:border-myorangeolor'>Send Message</button>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    </section>

    </>
  )
}
