import {AUTH_ERROR,AUTH_ERROR_REMOVE,AUTH_LOADING,AUTH_END_LOADING,AUTH_SIGNUP_MSG }  from '../constants/actionTypes';

const errorReducer = (state={ error:null,isAuthLoading:false },action) => {
    switch (action.type) {
        case AUTH_LOADING:
            return {...state,isAuthLoading:true}
        case AUTH_END_LOADING:
            return {...state,isAuthLoading:false}
        case AUTH_ERROR:
            // console.log();
            return {...state, error: action?.error?.response?.data?.message};
            return {...state,isAuthLoading:false}
        case AUTH_SIGNUP_MSG:
            // console.log();
            return {...state, error: "We have received your request, You will be able to login after verification."};
        case AUTH_ERROR_REMOVE:
            return {...state, error: null}
        default:
            return state;
    }
}

export default errorReducer;