import * as api from '../api';
import * as actionType from '../constants/actionTypes';

// Action creator

export const signIn = (formData,history) => async (dispatch) => {
    try {
        const {data} = await api.signIn(formData);
        dispatch({type:actionType.AUTH_LOADING});
             dispatch({type:actionType.AUTH,data});
        dispatch({type:actionType.AUTH_END_LOADING});
        history('/');
    } catch (error) {
        console.log(error.message);
        dispatch({type:actionType.AUTH_ERROR,error});
    }
}

export const signUp = (formData,history) => async (dispatch) => {
    try {
        const {data} = await api.signUp(formData);
        dispatch({type:actionType.AUTH_LOADING});
             dispatch({type:actionType.LOGOUT});
        dispatch({type:actionType.AUTH_END_LOADING});
        // const signUpMSg = { msg:"" }
        dispatch({type:actionType.AUTH_SIGNUP_MSG});
        history('/login');
    } catch (error) {
        console.log(error.message);
        dispatch({type:actionType.AUTH_ERROR,error});
    }
}

