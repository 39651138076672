import React from 'react'
import { useSelector } from 'react-redux';
import BrandLogos from './BrandLogos'
import footerBg from '../Images/footer_bg_img.png';
import { Link } from 'react-router-dom';


function Footer() {
    const { categories,isLoading } = useSelector(( state )=> state.categories);
    if(isLoading){ return 'Loading'; }
    if(!isLoading && !categories.length){ return 'No Category'; }
    const listItems = categories.map((item) =>
    (
        item.showInMenu == 1 && (
            <li key={item._id}>
            <Link to={`/category/${item._id}`} className='text-white font-primary text-base duration-300 hover:text-myorangeolor md:mb-6 mb-2 block'>
                {item.title}
            </Link>
        </li>
        )
    )
    );

  return (
    <>
    <footer>
        <BrandLogos/>


        <section className='middle_footer py-10 bg-cover bg-no-repeat bg-center' style={{ backgroundImage: `url(${footerBg})` }}>
            <div className='container mx-auto'>
                <div className='foo_content flex md:flex-row flex-col md:mx-0 mx-3 gap-4'>

                    <div className='foo_col_one bas basis-4/5'>
                        <h4 className='text-white font-primary font-semibold text-xl mb-5'>Shop by categories</h4>

                        <ul className='foo-menu list_four_col'>
                            {listItems}
                         </ul>

                        

                    </div>

                    <div className='foo_col_two bas basis-1/5'>
                        <h4 className='text-white font-primary font-semibold text-xl mb-5'>My Account</h4>
                        <ul className='foo-menu'>
                            <li>
                                <Link to="/login" className='text-white font-primary text-base duration-300 hover:text-myorangeolor md:mb-6 mb-2 block'>Login</Link>
                            </li>
                            <li>
                                <Link to="/signup" className='text-white font-primary text-base duration-300 hover:text-myorangeolor md:mb-6 mb-2 block'>Register</Link>
                            </li>
                            <li>
                                <Link to="/checkout" className='text-white font-primary text-base duration-300 hover:text-myorangeolor md:mb-6 mb-2 block'>Checkout</Link>
                            </li>
                            <li>                        
                                <Link to="/contact-us" className='text-white font-primary text-base duration-300 hover:text-myorangeolor md:mb-6 mb-2 block'>Contact us</Link>
                            </li>
                            <li>
                                <a href="/" className='text-white font-primary text-base duration-300 hover:text-myorangeolor md:mb-6 mb-2 block'>Shipping Policy  </a>
                            </li>
                        </ul>
                    </div>

                    <div className='foo_col_two bas basis-1/5'>
                    <h4 className='text-white font-primary font-semibold text-xl mb-5'>Customer Care </h4>
                    <ul className='foo-menu'>
                            <li>
                                <a href="tel:+678-574-7818" className='text-white font-primary text-base duration-300 hover:text-myorangeolor md:mb-6 mb-3 block'>Phone Number: 678-574-7818</a>
                            </li>
                            <li>
                                <a href="tel:+678-574-7818" className='text-white font-primary text-base duration-300 hover:text-myorangeolor md:mb-6 mb-3 block'>Fax: 6785747820</a>
                            </li>
                            <li>
                                <a href="mailto:wexorders@gmail.com" className='text-white font-primary text-base duration-300 hover:text-myorangeolor md:mb-6 mb-3 block'>Email: wexorders@gmail.com</a>
                            </li>
                            <li>
                                <a href="https://goo.gl/maps/FJhHcQ1AvZPKQPzN8" target="_blank" rel="noreferrer" className='text-white font-primary text-base duration-300 hover:text-myorangeolor md:mb-6 mb-3 block'>850 Industrial Park Dr. Marietta, GA 30062</a>
                            </li>
                        </ul>
                    </div>



                </div>
            </div>
        </section>

        <section className='copyright_block py-5 text-center'>
            <div className='container mx-auto'>
                <p className='font-primary text-base text-black'>© 2023 Copyright Wholesale Xpress, All Rights Reserved.  </p>
            </div>
        </section>
    </footer>
    </>
  )
}

export default Footer