import React from 'react'

export const Thankyou = () => {
  return (
    <section className='thank_sec py-[8%]'>
        <div className='container mx-auto'>
            <div className='text-center md:mx-38 mx-12'>
                <p className='text-black font-primary md:text-4xl text-2xl font-semibold'>Thank you for your order. We appreciate your <span className='text-myorangeolor'>business!</span></p>
            </div>
        </div>
    </section>
  )
}
