import React, { useState } from 'react';
import HomeTopBanner from './HomeTopBanner';
import TopOffer from './TopOffer';
import TopSelling from './TopSelling';
import { useSelector } from 'react-redux';
import Loading from './Loading';
import Popup from './Popup';

function Drinks() {

  const { drinks, isWidgetLoading } = useSelector((state) => state.page);
  const { categories, isCatLoading } = useSelector((state) => state.categories);

  if (isWidgetLoading) { 
    return <Loading />; 
  }
  if (isWidgetLoading) { 
    return <Loading />; 
  }
  // Determine which categories to use based on the page

  if (!drinks) {
    return <Loading />;
  }
  const pageCats = drinks.categories;
  if(!pageCats) {
    return <Loading />;
  }

  // Filter selected categories
 
  const selectedCategories = categories.filter(category =>
    pageCats.includes(category._id)
  );
 
  // Check if selected categories are found
  if (selectedCategories.length === 0) {
    return <div>No Category Found</div>;
  }

  return (
    <>
      <div className='mt-10'>
      <TopSelling title="Browse Top Drinks" page='mainCat' drinksBanner='' cigarettesBanner='' categories={selectedCategories} />
      </div>
    </>
  );
}

export default Drinks;
