import React from 'react'
import { useDispatch,useSelector } from 'react-redux';


export const Error = () => {
    const dispatch = useDispatch();
    
    const {error} = useSelector(( state )=> state.error);
    


    if(typeof error !== 'undefined' && error !== null){
        return (
            <div className='bg-red-600 text-white p-3 mb-5 rounded'>{error}</div>
          )
    }else{
        return ( <> </> )
    }

}
