import * as api from '../api';
import * as actionType from '../constants/actionTypes';

// Action creator

export const getCategories = () => async (dispatch) => {
    try {
        dispatch({type:actionType.START_CAT_LOADING});
        const {data} = await api.fetchCategories();
        dispatch({type:actionType.FETCH_ALL_CATEGORIES, payload:data});
        dispatch({type:actionType.END_CAT_LOADING});
    } catch (error) {
        console.log(error.message);
    }
}

export const getCategory = (id) => async (dispatch) => {
    try {
        dispatch({type:actionType.START_CAT_LOADING});
        const {data} = await api.fetchCategory(id);
        dispatch({type:actionType.FETCH_CATEGORY, payload:data});
        dispatch({type:actionType.END_CAT_LOADING});
    } catch (error) {
        console.log(error.message);
    }
}