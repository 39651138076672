import React,{useEffect} from "react";
import { Link } from 'react-router-dom';
import { useDispatch,useSelector } from "react-redux";
import { getProductsByCategory } from "../../actions/products.js";


const CustomPagination = ({category,page}) => {
    const dispatch = useDispatch();
    const { numberOfPages } = useSelector((state)=>state.products);
   // console.log(numberOfPages);
    useEffect(()=>{
        if(page) dispatch(getProductsByCategory(category)); 
    },[page]);
    return(
        <>
        
        
        {/* // <Pagination
        //     classes={{ ul: classes.ul }}
        //     count={numberOfPages}
        //     page={Number(page) || 1}
        //     variant='outlined'
        //     color='primary'
        //     renderItem={(item)=>(
        //         <PaginationItem {...item} component={Link} to={`/posts?page=${item.page}`} />
        //     )}
        // /> */}

        </>
    );
}

export default CustomPagination;