import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { getProductsBySearch } from '../actions/products';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';


export const SearchBar = () => {
    const dispatch = useDispatch();
    const history = useNavigate();
    const [search,setSearch] = useState('');
    const searchProduct = () => {

        dispatch(getProductsBySearch(search,history));
    }
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            searchProduct();
        }
      }
  return (
    <form>
    <div className='form_row flex flex-row justify-center my-3'>
        <div className='search_input_form basis-[95%]'>
            <input 
            type="text" 
            onChange={(e)=>setSearch(e.target.value)} 
            onKeyDown={handleKeyDown}
            value={search}
            placeholder="Search here…" 
            name="search"
            className="px-3 py-3 placeholder-[#cacacb] text-[#000] relative bg-[#f3f3f4] text-base 
            border-2 rounded-tl-[30px] rounded-bl-[30px] border-solid border-myorangeolor rounded-none w-full focus:border-myorangeolor searchInput"
            />
        </div>
        <div className='btn_input_form basis-[5%]'>
            <button 
            onClick={searchProduct}
            className="py-3 px-11 text-white
                font-primary font-bold border-2 bg-myorangeolor border-l-0 border-myorangeolor duration-300 hover:bg-mydarkcolor
                w-full rounded-tr-[30px] rounded-br-[30px]" 
            type='button'>
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
            </button>
        </div>
    </div>
</form>
  )
}
