import * as actionTypes from '../constants/actionTypes';

export default (state = { isOrderLoading:true, orders:[], order:'' }, action) => {

    switch (action.type) {
       case actionTypes.START_ORDER_LOADING:
            return {...state,isOrderLoading:true}
       case actionTypes.END_ORDER_LOADING:
            return {...state,isOrderLoading:false}
       case actionTypes.FETCH_ALL_ORDER:
            return {
                ...state,
                orders:action.payload.data,               
            };
       case actionTypes.FETCH_ORDER_BY_ID:
            return {...state, order:action.payload.data}   

        default:
            return state;
    }

}