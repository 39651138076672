import {AUTH,LOGOUT }  from '../constants/actionTypes';

const authReducer = (state={ isAuthLoading:true,authData:null, authError:null },action) => {
    switch (action.type) {
        case AUTH:
            localStorage.setItem('profile',JSON.stringify({ ...action?.data }))
            return {...state, authData: action?.data,authError:null};
        case LOGOUT:
            localStorage.clear();
            return {...state, authData: null };
        default:
            return state;
    }
}

export default authReducer;