import React from "react";
import BgTopHome from "../Images/top_bg_single_img.png";
import Carousel from "react-multi-carousel";
import 'react-multi-carousel/lib/styles.css';
import { useSelector } from "react-redux";

import { SERVER_URL } from "../constants/actionTypes";
import { Link } from "react-router-dom";

function HomeTopBanner(banner) {
  const { isSlideLoading,slides } = useSelector(( state )=> state.slider);
  if(isSlideLoading){ return 'Loading'; }
  if(!isSlideLoading && !slides.length){ return ''; }
 
  const items = slides.map((slide)=>(
    <Link key={slide._id}  to={(slide.link != '')? slide.link : '#'}>
    <div key={slide._id} 
      className="top_banner_inner_img text-center flex flex-col justify-center items-center
      bg-cover bg-no-repeat bg-center md:min-h-[400px] min-h-[125px]"
      style={{
        backgroundImage: `url( ${
          slide.banner !== ""
          ? `${SERVER_URL + slide.banner}`
          : require("../Images/top_bg_single_mid_img.png")
        })`,
      }}>
    </div>
    </Link>
  ))
  const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 1
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
      }
    };

  return (

    <>
    
    <section className="top_banner_section mb-8">
        <Carousel responsive={responsive} showDots={false} 
          infinite={true} 
          arrows={true}
          autoPlay={true} 
          autoPlaySpeed={5000} 
          transitionDuration={1000}>
            {items}
           
        </Carousel>
    </section>

    </>
  );
}

export default HomeTopBanner;
