import * as actionTypes from '../constants/actionTypes';

export default (state = { isLoading:true,product:'', products:[],topProducts:[] }, action) => {

    switch (action.type) {
        case actionTypes.START_LOADING:
            return {...state,isLoading:true}
       
       case actionTypes.END_LOADING:
            return {...state,isLoading:false}
        case actionTypes.FETCH_ALL_PRODUCTS:
            return {
                ...state,
                products:action.payload.data,
                currentPage:action.payload.currentPage,
                numberOfPages:action.payload.numberOfPages
            };
        case actionTypes.FETCH_ALL_PRODUCTS_BY_SEARCH:
            
            return {
                ...state,
                products:action.payload.data,
                currentPage:action.payload.currentPage,
                numberOfPages:action.payload.numberOfPages
            };
            
        case actionTypes.FETCH_ALL_PRODUCTS_BY_CATEGORY:
            return {
                ...state,
                products:action.payload.data,
                currentPage:action.payload.currentPage,
                numberOfPages:action.payload.numberOfPages
            };      
        case actionTypes.FETCH_PRODUCT_BY_ID:
            return {...state, product:action.payload.data}            
        case actionTypes.FETCH_TOP_PRODUCTS:            
            return {...state, topProducts:action.payload.data}
        default:
            return state;
    }

}