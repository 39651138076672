import * as actionTypes from '../constants/actionTypes';

export default (state = { isBannerLoading:true,banner:'', banners:[] }, action) => {

    switch (action.type) {
        case actionTypes.START_BANNER_LOADING:
            return {...state,isBannerLoading:true}
       
       case actionTypes.END_BANNER_LOADING:
            return {...state,isBannerLoading:false}
       
       case actionTypes.FETCH_ALL_BANNERS:
            return {
                ...state,
                banners:action.payload.data,               
            };
        case actionTypes.FETCH_BANNER_BY_ID:
            return {...state, banners:action.payload.data };

        default:
            return state;
    }

}